import { render, staticRenderFns } from "./InstagramAdsEditorForm.vue?vue&type=template&id=5ffef9ea&scoped=true&"
import script from "./InstagramAdsEditorForm.vue?vue&type=script&setup=true&lang=ts&"
export * from "./InstagramAdsEditorForm.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./InstagramAdsEditorForm.vue?vue&type=style&index=0&id=5ffef9ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffef9ea",
  null
  
)

export default component.exports