import { render, staticRenderFns } from "./GoogleBannerGenerationAdInputs.vue?vue&type=template&id=18832096&scoped=true&"
import script from "./GoogleBannerGenerationAdInputs.vue?vue&type=script&setup=true&lang=ts&"
export * from "./GoogleBannerGenerationAdInputs.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./GoogleBannerGenerationAdInputs.vue?vue&type=style&index=0&id=18832096&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18832096",
  null
  
)

export default component.exports